import DocumentMeta from "react-document-meta";

export const DefaultMeta = () => {
    const meta = {
        title: 'Домашняя страница Дениса Блинова',
        meta: {
            charset: 'utf-8',
            name: {
                description: 'desdenbro info website',
                'theme-color': '#f6f8fa'
            }
        }
    };

    return (
        <DocumentMeta {...meta}/>
    );
}