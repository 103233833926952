import React, { useState } from 'react';
import './LogicPageStyle.css';
import { LogicFormulaService } from '../../../controllers/LogicFormulaService';
import { CupPage } from '../../special/cup/CupPage';

export const LogicPage = () => {
    const [type, setType] = useState('solution');

    return (
        <div className='pageLogic'>
            <div className='pageLogic__check_filter'>
                <div className={'pageLogic__check_filter__type_btn' + (type === 'solution' ? ' selected' : '')} onClick={() => { setType('solution') }}>решение выражения</div>
                <div className={'pageLogic__check_filter__type_btn' + (type === 'compare' ? ' selected' : '')} onClick={() => { setType('compare') }}>сравнение выражений</div>
            </div>
            {
                type === 'solution' && <LogicSolutionContainer />
                ||
                type === 'compare' && <CupPage />
            }
        </div>
    )
}

const LogicSolutionContainer = () => {
    const [text, setText] = useState('');

    return (
        <div className='pageLogic__container'>
            {/*<div className='warning'>
                калькулятор еще в разработке, так что если что-то не работает, выглядит криво или непонятно - значит скоро исправлю
            </div>*/}
            <div className="pageLogic__container__textarea">
                <textarea name='formulaPlace' autoFocus maxLength={1000} placeholder='укажите логическое выражение' onChange={(e) => { setText(e.target.value); }}></textarea>
            </div>
            <LogicResultsContainer text={text} />
        </div>
    )
}

const LogicCompareContainer = () => {
    const [text, setText] = useState('');

    return (
        <div className='pageLogic__container'>
            
        </div>   
    )
}

const LogicResultsContainer = ({ text }) => {
    const [type, setType] = useState('grid');
    var isTextEmpty = text == null || text.trim() === '';
    if (isTextEmpty) return (<div></div>);

    var isTextValid = LogicFormulaService.checkFormula(text);
    var data = isTextValid ? LogicFormulaService.getResultsArray(text) : [];

    return (
        <div className='pageLogic__container__results'>
            <div className='pageLogic__container__results__header'>
                <div className='pageLogic__container__results__header__title'>
                    результаты выражения:
                </div>
                <div className='pageLogic__container__results__header__filter'>
                    <div className={'pageLogic__container__results__header__filter__button' + (type === 'grid' ? ' selected' : '')} onClick={ () => { setType('grid'); } }>сетка</div>
                    <div className={'pageLogic__container__results__header__filter__button' + (type === 'list' ? ' selected' : '')} onClick={ () => { setType('list'); } }>список</div>
                </div>
            </div>
            <div className='pageLogic__container__results__data'>
                {
                    !isTextValid
                    ? (
                        <div className='pageLogic__container__results__data__nodata'>выражение не поддается решению</div>
                    )
                    : (
                        type === 'grid' && ( <LogicResultsContainerGrid dataByFormula={data} /> ) 
                        ||
                        type === 'list' && ( <LogicResultsContainerList dataByFormula={data} /> )
                    )
                }
            </div>
        </div>
    )
}

const LogicResultsContainerList = ({ dataByFormula }) => {
    if (dataByFormula == null || dataByFormula.length === 0) {
        return (<div></div>);
    }

    return (
        <div className='pageLogic__container__results__data__list_zone'>
            <div className='list'>
                {dataByFormula.map(
                    (data, i) => {
                        return (
                            <div className='case' key={i} >
                                <div className='case__parsed_formula'>
                                    {data.parsedText}
                                </div>
                                <div className={'case__result ' + data.result.toString() }>
                                    {data.result.toString()}
                                </div>
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}

const LogicResultsContainerGrid = ({ dataByFormula }) => {
    const [selCase, setSelCase] = useState();

    if (dataByFormula == null || dataByFormula.length === 0) {
        return (<div></div>);
    }

    var countToFullArr = 14 - dataByFormula.length % 14;
    if (countToFullArr === 14) countToFullArr = 0;
    var arrToRender = [...dataByFormula, ...Array(countToFullArr)];

    var selectCase = (data) => {
        if (selCase != null && selCase.isSelected) {
            selCase.isSelected = false;
        }

        if (data != null) {
            data.isSelected = true;
        }

        setSelCase(data);
    };

    if (selCase != null && arrToRender.indexOf(selCase) === -1) {
        selectCase(null);
    }

    return (
        <div className='pageLogic__container__results__data__grid_zone'>
            <div className='grid'>
                {arrToRender.map(
                    (data, i) => {
                        var classToCell = 'grid__cell';
                        if (data != null) {
                            classToCell += ' filled ';
                            if (data.isSelected) classToCell += ' selected ';
                            classToCell += data.result.toString();
                        }
                        return (
                            <div key={i} className={classToCell} onClick={ () => { selectCase(data); } }></div>
                        )
                    }
                )}
            </div>
            {   
                selCase != null &&
                (
                    <div className='case'>
                        <div className='case__parsed_formula'>
                            {selCase.parsedText}
                        </div>
                        <div className={'case__result ' + selCase.result.toString() }>
                            {selCase.result.toString()}
                        </div>
                    </div>
                )
            }
        </div>
    )
}
