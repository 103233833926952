export class LogicFormulaService {
    static checkFormula(text) {
        var matches = this._getMacthes(text);
        var count = matches.length;
        if (count === 0) return false;

        var result = text;
        for (var matchIndex = 0; matchIndex < count; matchIndex++) {
            var rgx = new RegExp("\\b" + matches[matchIndex] + "\\b", 'gm');
            result = result.replace(rgx, false);
        }

        try {
            eval(result);
        }
        catch { 
            return false;
        }

        return true;
    }

    static getResultsArray(text) {
        var matches = this._getMacthes(text);
        if (matches.length === 0) return [];

        var trueIndex = matches.indexOf('true');
        if (trueIndex > -1) matches.splice(trueIndex, 1);
        
        var falseIndex = matches.indexOf('false');
        if (falseIndex > -1) matches.splice(falseIndex, 1);

        var count = matches.length;
        if (count === 0)
        {      
            return [{
                code: null,
                parsedText: text,
                result: eval(text)
            }];     
        }

        var resultArr = [];
        var numberOfVariants = Math.pow(2, count);
        for (var index = 0; index < numberOfVariants; index++) {
            var obj = {
                code: this._getBinaryCode(index, count),
                parsedText: text,
                result: undefined
            };

            for (var matchIndex = 0; matchIndex < count; matchIndex++) {
                var rgx = new RegExp("\\b" + matches[matchIndex] + "\\b", 'gm');
                obj.parsedText = obj.parsedText.replace(rgx, (obj.code[matchIndex] == true).toString());
            }

            obj.result = eval(obj.parsedText);

            resultArr.push(obj);
        }

        return resultArr;
    }
    
    static _getMacthes(text) {
        if (text == null || text.length === 0) return [];

        var matches = text.match(/[a-zA-Z]+/gm);
        if (matches == null) return [];

        matches = [...new Set(matches)];
        if (matches == null || matches.length === 0) return [];

        return matches;
    }

    static _getBinaryCode(number, fullLength) {
        var binaryStr = '';

        do {
            var cut = number % 2;
            binaryStr = cut + binaryStr;
            if (cut === 1) number = number - 1;

            number = number / 2;
            fullLength -= 1;
        } while(number > 0)
        
        if (fullLength > 0) {
            do { 
                binaryStr = "0" + binaryStr;
                fullLength -= 1;
            } while (fullLength > 0);
        }

        return binaryStr;
    }
}