export class BestSumService {
    static getBestSums(wishSum, arrOfNumbers) {
        wishSum = parseFloat(wishSum);
        if (isNaN(wishSum) || wishSum == null || wishSum < 0) return 'не удалось подобрать';
        if (arrOfNumbers == null || arrOfNumbers.length === 0) return 'отсутсвуют входные параметры';

        var preparedArr = this.getPreparedArray(wishSum, arrOfNumbers);
        if (preparedArr == null || preparedArr.length === 0) return 'отсутствуют подходящие входные параметры'

        var res = this.getBestSumsByPreparedArray(wishSum, preparedArr);
        return res;
    }

    static getPreparedArray(wishSum, arrOfNumbers) {
        var correctArr = [];
        for (var numTxt of arrOfNumbers) {
            if (numTxt == null || (typeof(numTxt) !== "number" && typeof(numTxt) !== "string")) continue;

            var num = numTxt;
            if (typeof(numTxt) === 'string')
            {
                if (numTxt.length === 0) continue;
                num = parseFloat(numTxt.replace(/\s/g, '').replace(',', '.'));
            }

            if (isNaN(num) || num == null || num > wishSum) continue;
            
            correctArr.push(num);
        }
        correctArr.sort((a, b) => { return b - a; });

        return correctArr;
    }

    static getBestSumsByPreparedArray(wishSum, preparedNumArr) {
        var dict = {};
        var index = 0;
        var maxVal = preparedNumArr[preparedNumArr.length - 1];
        for (var num of preparedNumArr) {
            this.getSumOfElements(wishSum, preparedNumArr, index, num, num.toString(), dict, maxVal);
            index += 1;
        }

        if (dict[wishSum] != null) {
            return {
                sum: wishSum,
                formulas: dict[wishSum]
            };
        } else {
            var keys = Object.keys(dict).sort((a, b) => { return a - b; });
            var bestValue = keys[keys.length - 1];
            return {
                sum: bestValue,
                formulas: dict[bestValue]
            };
        }
    }

    static getSumOfElements(wishSum, preparedNumArr, startIndex, currentSum, currentFormula, dict, maxVal) {
        if (maxVal <= currentSum)
        {
            if (dict[currentSum] != null) {
                if (dict[currentSum].indexOf(currentFormula) === -1) {
                    dict[currentSum].push(currentFormula);
                }
            } else {
                dict[currentSum] = [currentFormula]
            }
        }

        var innerIndex = startIndex + 1;
        while(innerIndex < preparedNumArr.length)
        {
            var nextNum = preparedNumArr[innerIndex];
            var sum = currentSum + nextNum;
            var formula = currentFormula + " + " + nextNum;

            if (innerIndex < preparedNumArr.length && sum <= wishSum) {
                this.getSumOfElements(wishSum, preparedNumArr, innerIndex, sum, formula, dict, maxVal);
            }
            innerIndex += 1;
        }
    }
}