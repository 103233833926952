import React from "react";
import { CustomMeta } from "../../../elements/metaTags/CustomMetaTag";
import './CupPageStyle.css';

export const CupPage = () => {
    return (
        <div className="pageCup__container">
            <CustomMeta data={{title: 'Что-то тут точно будет'}} />
            <div>Пока делаю, терпение.</div>
        </div>
    )    
}