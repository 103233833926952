import './SocialsPageStyle.css';

export const SocialsPage = () => {
    return (
        <div className="socialsPage__container">
            <SocBlock name='Telegram' color='tg' link='/tg' />
            <SocBlock name='Skype' color='skp' link='/skype' />
            <SocBlock name='Почта' color='ml' link='/mail' />
            <SocBlock name='Хабр.Карьера' color='hc' link='/habr' />
            <SocBlock name='ВКонтакте' color='vk' link='/vk' />
            <SocBlock name='Instagram' color='inst' link='/insta' />
            <SocBlock name='Творческий' color='inst' link='/art_insta' />
            <SocBlock name='Facebook' color='fb' />
            <SocBlock name='X' color='x' link='/x' />
            <SocBlock name='Steam' color='stm' link='/steam' />
            <SocBlock name='DTF' color='dtf' link='dtf' />
            <SocBlock name='Coub' color='coub' link='coub' />
            <SocBlock name='Twitch' color='tw' link='twitch' />
            <SocBlock name='Youtube' color='yt' link='youtube' />
            <SocBlock name='Threads' color='thr' link='threads' />
        </div>
    )
}

const SocBlock = ({ name, color, link }) => {
    return (
        <a href={link} target="_blank" rel="noreferrer" className='socialsPage__container__block common'>
            <div className={'text ' + color}>
                {name}
            </div>
        </a>
    )
}