import React from "react";
import './RedirectByLinkStyle.css';
import { Page404 } from "../../errors/Page404";
import { SearchLinkService } from "../../../controllers/SearchLinkService";
import { CustomMeta } from "../../../elements/metaTags/CustomMetaTag";

export const RedirectByLink = ({ ln }) => {
    var linkObj = SearchLinkService.findLinkObj(ln);

    // уходим на 404
    if (linkObj == null) return <Page404 />

    window.location.href = linkObj.link;
    return (
        <div className="pageRedir__container">
            <CustomMeta data={{title: 'Куда ведут эти дороги...'}} />
            <div style={{ fontSize: '2.3em' }} className="loading">...</div>
            <div>Переходим на страницу {linkObj.locName}</div>
        </div>
    )
}