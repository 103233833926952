export class SearchLinkService {
    static getObj() {
        return {
            'telegram': { link: 'https://t.me/desdenbro', locName: 'Telegram' },
            'tg': { link: 'https://t.me/desdenbro', locName: 'Telegram' },
            'skype': { link: 'https://join.skype.com/invite/Hzhxl14XC5CJ', locName: 'Skype' },
            'mail': { link: 'mailto:blinov.d.d@yandex.ru', locName: 'почты' },

            'habr_carieer': { link: 'https://career.habr.com/desdenbro', locName: 'Хабр.Карьера' },
            'habr': { link: 'https://career.habr.com/desdenbro', locName: 'Хабр.Карьера' },
            'github': { link: 'https://github.com/DesDenBro', locName: 'Github' },

            'vk': { link: 'https://vk.com/desdenbro', locName: 'ВКонтакте' },
            'insta':  { link: 'https://www.instagram.com/desdenbro/', locName: 'Instargam' } ,
            'instagram': { link: 'https://www.instagram.com/desdenbro/', locName: 'Instargam' },
            'art_insta': { link: 'https://www.instagram.com/desdenart/', locName: 'творческого Instargam' },
            'art_instagram': { link: 'https://www.instagram.com/desdenart/', locName: 'творческого Instargam' },
            'fb': { link: 'https://www.facebook.com/profile.php?id=100001563107101', locName: 'Facebook' } ,
            'facebook': { link: 'https://www.facebook.com/profile.php?id=100001563107101', locName: 'Facebook' },
            'x': { link: 'https://x.com/desdenbro', locName: 'X' },
            'steam': { link: 'https://steamcommunity.com/id/desdenbro/', locName: 'Steam' } ,
            'dtf': { link: 'https://dtf.ru/u/59238-denis-blinov', locName: 'DTF' },
            'coub': { link: 'https://coub.com/desdenbro', locName: 'Coub' },
            'twitch': { link: 'https://www.twitch.tv/desdenbro', locName: 'Twitch' },
            'youtube': { link: 'https://www.youtube.com/channel/UCRVypUinZgwh_lx-63qSZtw', locName: 'Youtube' },
            'threads': { link: 'https://www.threads.net/@desdenbro', locName: 'Threads' }
        };
    }

    static findLinkObj(linkName) {
        if (linkName == null || typeof(linkName) !== 'string') return null;

        linkName = linkName.trim();
        if (linkName.length === 0) return null;

        var namesObj = this.getObj();
        var link = namesObj[linkName];
        if (link == null) return null;

        return link;
    }
    
    static findLink(linkName) {
        return this.findLinkObj(linkName)?.link ?? null;
    }
}