import React from "react";
import { Route, Routes } from "react-router-dom";
  
import { MainPage } from "./pages/main/MainPage";

import { CatbugPage } from "./pages/special/catbug/CatbugPage";
import { SymbolsPage } from "./pages/miniapps/symbols/SymbolsPage";
import { SocialsPage } from "./pages/socials/SocialsPage";
import { LogicPage } from "./pages/miniapps/logic/LogicPage";

import { Page404 } from "./pages/errors/Page404";
import { CupPage } from "./pages/special/cup/CupPage";
import { BestSumPage } from "./pages/miniapps/bestsum/BestSumPage";

export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPage />} exact/>
            <Route path="/:redirect" element={<MainPage />} />
            <Route path="/catbug" element={<CatbugPage />} />
            <Route path="/socials" element={<SocialsPage />} />
            <Route path="/symbols" element={<SymbolsPage />} />
            <Route path="/logic" element={<LogicPage />} />
            <Route path="/bestsum" element={<BestSumPage />} />

            <Route path="/waitplease" element={<CupPage />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    )    
}