import React from "react";
import { CustomMeta } from "../../elements/metaTags/CustomMetaTag";
import './Page404.css';

export const Page404 = () => {
    return (
        <div className="page404__container">
            <CustomMeta data={{title: '404 - Клуб любителей кожевенного ремесла два блока вниз'}} />
            <span className="page404__container__error-text">
                404, сорян
            </span>
            <div className="page404__container__youtube-v">
                <iframe width="720" height="400" src="https://www.youtube.com/embed/Be1ZHRri78I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>            
        </div>
    )    
}