import React, { useEffect, useState } from 'react';
import { BestSumService } from '../../../controllers/BestSumService';
import { CustomMeta } from '../../../elements/metaTags/CustomMetaTag';
import './BestSumPageStyle.css';

export const BestSumPage = () => {
    const [inputData, setInputData] = useState({});

    return (
        <div className="pageBestSum__container">
            <CustomMeta data={{title: 'Подсчет лучшей суммы'}} />
            <InputBlock funcUpdateInput={setInputData} />
            <OutputBlock data={inputData} />
        </div>
    )
}

const OutputBlock = ({data}) => {
    var result = BestSumService.getBestSums(data.wishSum, data.numbers);
    return (
        <div className='pageBestSum__container__output_data'>
            <div className='pageBestSum__container__output_data__result_sum'>
                {
                    data.wishSum === result.sum 
                    ? <span className='pageBestSum__container__output_data__result_sum__full_eq'>{result.sum}</span>
                    : result.sum
                }
            </div>
            <div className='pageBestSum__container__output_data__result_values'>
            {
                result.formulas && 
                <div>
                {
                    result.formulas.slice(0, 5).map(
                        (data, i) => {
                            return (
                                <div key={i} className='pageBestSum__container__output_data__result_values__formula'>
                                    {data}    
                                </div>
                            )
                        }
                    )
                }
                {
                    (() => {
                        var leftCnt = result.formulas.length - 5;
                        if (result.formulas.length <= 5) return '';

                        var leftCntMod = leftCnt % 10;
                        return (
                            <div className='pageBestSum__container__output_data__result_values__formula'>
                                {'и еще ' + (leftCnt)} 
                                {leftCntMod === 1 
                                    ? ' вариант'
                                    : leftCntMod === 0 || leftCntMod >= 5 
                                        ? ' вариантов' 
                                        : ' варианта'}
                                {' суммы'}
                            </div>
                        )
                    })()
                }               
                </div>
            }
            </div>
        </div>
    );
}

const InputBlock = ({ funcUpdateInput }) => {
    const [wishSum, setWishSum] = useState(0);
    const [numbersToSum, setNumbersToSum] = useState([]);
    const [rawNumbersToSum, setRawNumbersToSum] = useState([]);

    function validateWishSum(wishSumText) {
        if (wishSumText == null || wishSumText.length === 0) return;
        
        wishSumText = wishSumText.replace(/\s/g, '').replace(',', '.');
        var parsedWishSum = parseFloat(wishSumText);
        if (isNaN(parsedWishSum)) return;

        setWishSum(parsedWishSum);
    }

    function validateNumbersToSum(rawNums) {
        setRawNumbersToSum(rawNums);
        if (rawNums == null || rawNums.length === 0) return;

        var nums = [];
        for (var rawNum of rawNums) {
            if (typeof(rawNum) !== "number" && (typeof(rawNum) !== 'string' || rawNum.length === 0)) continue;

            rawNum = rawNum.replace(/\s/g, '').replace(',', '.');
            var num = parseFloat(rawNum);
            if (isNaN(num) || num == null || num === 0) continue;
            
            nums.push(num);
        }
        setNumbersToSum(nums);
    }

    useEffect(() => {
        document.getElementById("wishSumInput").focus();
    }, []);

    useEffect(() => {
        funcUpdateInput({
            wishSum: wishSum,
            numbers: numbersToSum
        });
    }, [wishSum, numbersToSum])

    return (
        <div className='pageBestSum__container__input_data' 
             style={{ "justifyContent": rawNumbersToSum && rawNumbersToSum.length > 30 ? "start" : "center" }}>
            <div className='pageBestSum__container__input_data__wish_sum'>
                <div className='title'>
                    Желаемое значение
                </div>
                <div className='pageBestSum__container__input_data__wish_sum__input_field'>
                    <input id='wishSumInput' keyboardType='numeric' onChange={(e) => { validateWishSum(e.target.value); }}></input>
                </div>
            </div>
            {
                wishSum > 0 && !isNaN(wishSum) &&
                <InputBlocks funcToUpdateValues={validateNumbersToSum} />     
            }
        </div>
    );
}

const InputBlocks = ({ funcToUpdateValues }) => {
    const [inputs, setInputs] = useState({});
    const [lastIndex, setLasIndex] = useState(2);

    var updateObjInputs = (newInputs) => {
        setInputs(JSON.parse(JSON.stringify(inputs)));
    }

    var funcAddNewInput = (forceIndex = null) => {
        var index = forceIndex == null ? lastIndex : forceIndex;
        inputs[index] = {
            index: index,
            textVal: ''
        };
        if (forceIndex == null) {
            setLasIndex(lastIndex + 1);
        }
    }

    var callIsChanged = (num, index, isTriggered) => {
        if (!isTriggered) { 
            funcAddNewInput(); 
        }
        inputs[index].textVal = num;
        updateObjInputs(inputs);
    }

    var deleteInput = (index) => {
        if (inputs == null || Object.keys(inputs).length === 0 || typeof(inputs[index]) === "undefined") return; 
        delete inputs[index];
        updateObjInputs(inputs);
    }

    useEffect(() => {
        funcToUpdateValues(Object.values(inputs).map(x => x.textVal));
    }, [inputs]);

    useEffect(() => {
        funcAddNewInput(0);
        funcAddNewInput(1);
        funcAddNewInput(2);
        updateObjInputs(inputs);
    }, []);

    return (
        <div className='pageBestSum__container__input_data__values'>
            <div className='title'>
                Входные значения
            </div>
            <div className='pageBestSum__container__input_data__values__inputs'>
                {Object.values(inputs).map(
                    (data, i) => {
                        return (
                            <InputFieldBlock keyIndex={i} val={data.textVal} i={data.index} callIsChanged={callIsChanged} deleteInput={deleteInput} key={data.index}/>  
                        )
                    }
                )}
                {
                    Object.keys(inputs).length <= 3 &&
                    <div className='hint'>
                        новые поля добавлются автоматически
                    </div>
                }
            </div>
        </div>
    )
}

const InputFieldBlock = ({ keyIndex, i, val, callIsChanged, deleteInput }) => {
    const [num, setNum] = useState(val);
    const [isTriggered, setIsTriggered] = useState(i === 0);

    useEffect(() => {
        if (num === '' && !isTriggered) return;
        if (num === '' && isTriggered && keyIndex > 2) {
            var el = document.getElementById("inputField" + (keyIndex - 1));
            if (el != null) el.focus();
            deleteInput(i);
            return;
        }
        callIsChanged(num, i, isTriggered);
        if (!isTriggered) {
            setIsTriggered(true); 
        }
    }, [num])

    return (
        <div className='pageBestSum__container__input_data__values__inputs__input_field'>
            <input id={ 'inputField' + keyIndex } keyboardType='numeric' value={num} onChange={(e) => { setNum(e.target.value); }}></input>
            {/*
                false && i >= 3 && isTriggered &&
                <a className='delete' onClick={() => { deleteInput(i) }}>&#120;</a>
            */}       
        </div>
    )
}