import React from 'react';
import {BrowserRouter} from 'react-router-dom'
import { DefaultMeta } from './elements/metaTags/DefaultMetaTag';
import { useRoutes } from './routes';

function App() {
  console.log('Привет, тут мало чего может быть интересного, но есть несколько пасхалочек =)')

  const routes = useRoutes()
  return (
    <BrowserRouter>
      <DefaultMeta />
      {routes}
    </BrowserRouter>
  );
}

export default App;
