export class CountSymbolsService {
    static getAllSymbolsCount(text, options) {
        if (text == null || text.length === 0) return 0;
        
        if (options == null || !options.checkNewLine) {
            text = text.replace(/(\n|\r\n)/g, '');
        }
        return text.length;
    }
    static getSymbolsWOWhitespacesCount(text, options) {
        if (text == null || text.length === 0) return 0;
        
        if (options == null || !options.checkNewLine) {
            text = text.replace(/(\n|\r\n)/g, '');
        }

        text = text.replace(/ /g, '');
        return text.length;
    }
    static getWordsCount(text) {
        if (text == null || text.length === 0) return 0;

        text = text.replace(/[^1-9a-zA-Zа-яёА-ЯЁ\s]/g, ' ');
        text = text.replace(/\s{2,}/g, ' ');
        text = text.replace(/(\n|\n\r)/g, ' ');
        var countOfWords = text.split(' ').filter((t) => { return t.length > 0}).length;

        return countOfWords;
    }
}