import React from "react";
import { useParams } from "react-router-dom";
import { RedirectByLink } from "../special/redirect/RedirectPage";
import { CupPage } from "../special/cup/CupPage"
import './MainPageStyle.css';

export const MainPage = () => {
    const params = useParams();
    var linkName = params?.redirect;
    if (linkName != null) { 
        return (<RedirectByLink ln={linkName} />)
    }

    // birthday
    var today = new Date(), birthDate = new Date(1995, 7, 20);
    var age = today.getFullYear() - birthDate.getFullYear();
    var birthM = today.getMonth() - birthDate.getMonth();
    if (birthM < 0 || birthM < 0 && today.getDate() < birthDate.getDate()) {
        age--;
    }
    var personYears = age;

    // career time
    var date = new Date((new Date()) - (new Date(2014, 8, 1)));    
    var finalDate = "Cтаж " + (date.getFullYear() - 1970) + " лет" 
    var m = date.getMonth();
    if (m > 0) {
        finalDate += " и " + m;
        switch(m){
            case 1: finalDate += " месяц"; break;
            case 2: case 3: case 4: finalDate += " месяца"; break;
            case 5: case 6: case 7: case 8: case 9: case 10: case 11: finalDate += " месяцев"; break;
        }
    }

    // выбор фото
    var photoArr = [
        "photo-0e872130-0700-42f9-9bb8-c346ebedcfcb", 
        "photo-6d562add-5f62-4411-bb9f-75db81fef595", 
        "photo-a9cc5bc1-098a-45b5-b961-9d5a6234dd9b", 
        "photo-a95be73e-753b-4f36-88ad-894e63768920", 
        "photo-af69e930-e488-4478-a66d-9dad022c1b19"
    ];
    var getRandomIntInclusive = function(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    };
    var photoLink = "pics/photos/" + photoArr[getRandomIntInclusive(0, photoArr.length - 1)] + ".jpg";
    
    return (
        <div className="mainPage__container">
            <div className="mainPage__container__info-block">   
                <div className="mainPage__container__info-block__photo">
                    <img src={photoLink} />
                </div>                
                <div className="mainPage__container__info-block__name-age">
                    Денис Блинов
                </div>
                <span className="mainPage__container__info-block__spec_info">
                    Возраст {personYears} лет
                </span>
                <span className="mainPage__container__info-block__spec_info">
                    Full-stack разработчик
                </span>
                <span className="mainPage__container__info-block__spec_info">
                    {finalDate}
                </span>
                <div className="mainPage__container__info-block__stack">
                    <code>C#</code>
                    <code>.NET</code>
                    <code>Node.js</code>
                    <code>TypeScript</code>
                    <code>JavaScript</code>
                    <code>React</code>
                    <code>JQuery</code>
                    <code>Knockout.js</code>
                    <code>HTML/CSS</code>
                    <code>Webpack</code>
                    <code>T-SQL</code>
                    <code>Microsoft SQL Server</code>
                    <code>PL/SQL</code>
                    <code>Oracle Database</code>
                    <code>Redis</code>
                    <code>Kafka</code>
                    <code>Regex</code>
                    <code>Git</code>
                </div>
                    
                <div className="mainPage__container__info-block__spec_info">
                    Местоположение 
                </div>
                <SocialContainer name='' iconPath='svg/location.svg' link='https://yandex.ru/maps/2/saint-petersburg' linkText='Россия, Санкт-Петербург' />
                <div className="mainPage__container__info-block__spec_info">
                    Контакты
                </div>
                <SocialContainer name='' iconPath='svg/mail.svg' link='mailto:blinov.d.d@yandex.ru' linkText='blinov.d.d@yandex.ru' />
                <SocialContainer name='career.habr' iconPath='svg/career.habr.svg' link='/habr_carieer' linkText='/desdenbro' />
                <SocialContainer name='telegram' iconPath='svg/telegram.svg' link='/tg' linkText='@desdenbro' />
                {/*<SocialContainer name='skype' iconPath='ico/skype.ico' link='/skype' linkText='kastiel.kastiel' />*/}
                <SocialContainer name='github' iconPath='svg/github.svg' link='/github' linkText='/desdenbro' />
                </div>
            <div className="mainPage__container__projects-block">
                <div className="mainPage__container__projects-block__container">
                    <div className="mainPage__container__projects-block__container__header">Мини-аппы</div>
                    <div className="mainPage__container__projects-block__container__blocks">
                        <a className="mainPage__container__projects-block__container__blocks__block" href="/symbols">                            
                            <div className="text_back" style={{ color: "rgb(30, 119, 17)" }}>&#49;&#52;&#51;&#50;</div>
                            <div className="text">Подсчет символов</div>
                        </a>
                        <a className="mainPage__container__projects-block__container__blocks__block" href="/logic">
                            <div className="text_back">&#65;&nbsp;&#38;&#38;&nbsp;&#33;&#67;</div>
                            <div className="text">Логический калькулятор</div>
                        </a>
                        <a className="mainPage__container__projects-block__container__blocks__block" href="/bestsum">
                            <div className="text_back">21+132</div>
                            <div className="text">Лучшая сумма</div>
                        </a>
                    </div>
                </div>
                {/* <div className="mainPage__container__projects-block__container">
                    <div className="mainPage__container__projects-block__container__header">Онлайн-проекты</div>
                    <div className="mainPage__container__projects-block__container__blocks">
                        <a className="mainPage__container__projects-block__container__blocks__block" href="/waitplease``">                            
                            <img className="hammer" src="svg/hammer.svg" />
                            <div className="text">В процессе переноса</div>
                        </a>
                    </div>
                </div> */}
                <div className="mainPage__container__projects-block__container">
                    <div className="mainPage__container__projects-block__container__header">Выступления</div>
                    <div className="mainPage__container__projects-block__container__blocks">
                        <a className="mainPage__container__projects-block__container__blocks__block" target="_blank" href="https://www.youtube.com/watch?v=COLfE53MB1M&t=1692s">
                            <img className="youtube" src="svg/youtube.svg" />
                            <div className="text">Ты (не) отрефакторишь</div>                            
                        </a>
                        <a className="mainPage__container__projects-block__container__blocks__block" target="_blank" href="https://youtu.be/a2aFwBAIEp8">
                            <img className="youtube" src="svg/youtube.svg" />
                            <div className="text">Краткий экскурс в мир .*</div>                            
                        </a>
                    </div>
                </div>
            </div>      
            <div style={{ display: "none" }} className="copyright">
                &#169;&nbsp;
                <span>2022</span>
                &nbsp;&nbsp;
                <span>Денис Блинов</span>
            </div>
        </div>  
    )    
}

const SocialContainer = ({ name, iconPath, link, linkText }) => {
    var socName = name.length === 0 ? '' : name + ' -';

    return (
        <a href={link} target="_blank" rel="noreferrer" className="mainPage__container__info-block__soc-containers-block__soc-container">
            <img src={iconPath} />
            <span className="mainPage__container__info-block__soc-containers-block__soc-container__text">
                {socName} {linkText}
            </span>
        </a>
    )
}