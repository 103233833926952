import React, { useState } from 'react';
import { CountSymbolsService } from '../../../controllers/CountSymbolsService';
import { CustomMeta } from '../../../elements/metaTags/CustomMetaTag';
import './SymbolsPageStyle.css';

export const SymbolsPage = () => {
    const [text, setText] = useState('');
    const [checkNewLine, setCheckNewLine] = new useState(false);

    return (
        <div className="pageSymbols__container">
            <CustomMeta data={{title: 'Подсчет символов и слов'}} />
            <div className="pageSymbols__container__textarea">
                <textarea autoFocus maxLength={100000} placeholder='вставьте ваш текст' onChange={(e) => { setText(e.target.value); }}></textarea>
            </div>
            <div className='pageSymbols__container__datas'>
                <SymbolsCountContainer title={'Всего символов'} func={CountSymbolsService.getAllSymbolsCount} text={text} options={{checkNewLine}} />
                <SymbolsCountContainer title={'Без пробелов'} func={CountSymbolsService.getSymbolsWOWhitespacesCount} text={text} options={{checkNewLine}} />
                <div>
                    <SymbolsCountContainer title={'Количество слов'} func={CountSymbolsService.getWordsCount} text={text}/>
                    <p style={{margin:'0'}}>
                        <label className="checkbox-ios">
                            <input type="checkbox" id='checkNewLine' name='checkNewLine' onChange={(e) => { setCheckNewLine(e.target.checked); }} />
                            <span className="checkbox-ios-switch"></span>
                        </label>  
                        <label style={{ "user-select": "none" }} htmlFor='checkNewLine' >Учитывать перенос строки как символ</label>                        
                    </p>
                </div>
            </div>
        </div>
    )
}

const SymbolsCountContainer = ({ title, func, text, options }) => {
    if (text == null) text = '';
    var count = func(text, options);

    return (
        <div className='data'>
            <div className='data_title'>
                {title}
            </div>     
            <TextSymbolsContainer count={count} />     
        </div>
    )
}

const TextSymbolsContainer = ({ count }) => {
    var finalText = <span></span>;

    if (count === 0) {
        finalText = <div>000</div>;
    } else if (count < 10) {
        finalText = <div><span className='gray'>00</span><span>{count}</span></div>;
    } else if (count < 100) {
        finalText = <div><span className='gray'>0</span><span>{count}</span></div>;
    } else {
        finalText = <div>{count}</div>
    }

    return (
        <div className='data_count'>
            {finalText}
        </div>
    )    
}