import { CustomMeta } from '../../../elements/metaTags/CustomMetaTag';
import './CatbugPageStyle.css';

export const CatbugPage = () => {
    return (
        <div className="pageCatbug__container">
            <CustomMeta data={{title: 'Catbug - персональная страница Котожука'}} />
            <iframe src="//coub.com/embed/15rzs4?muted=false&autostart=true&originalSize=true&startWithHD=true" allowfullscreen frameborder="0" width="1280" height="720" allow="autoplay"></iframe><script async src="//c-cdn.coub.com/embed-runner.js"></script>
        </div>
    )
}